import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config/firebase-config';

const FirebaseImage = ({ path }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImageUrl = async () => {
      const storageRef = ref(storage, path);
      try {
        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
      } catch (error) {
        console.error('Unable to retrieve the image URL', error);
      }
    };

    fetchImageUrl();
  }, [path]);

  return imageUrl ? (
    <img src={imageUrl} alt="From Firebase" />
  ) : (
    <p>Loading image...</p>
  );
};

export default FirebaseImage;
