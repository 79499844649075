import React from 'react';
import './style.css';
import { FaTwitter, FaBehance } from 'react-icons/fa';
import { socialprofils } from '../../content_option';

export const Socialicons = (params) => {
  return (
    <div className="stick_follow_icon">
      <ul>
        {socialprofils.twitter && (
          <li>
            <a href={socialprofils.twitter}>
              <FaTwitter />
            </a>
          </li>
        )}
        {socialprofils.behance && (
          <li>
            <a href={socialprofils.behance}>
              <FaBehance />
            </a>
          </li>
        )}
      </ul>
      <p>Mes réseaux</p>
    </div>
  );
};
