import React, { useState } from 'react';
import './style.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import {
  dataportfolioImg,
  dataportfolioVideo,
  dataportfolioAffiches,
  meta,
} from '../../content_option';

export const Portfolio = () => {
  const [showImages, setShowImages] = useState(true);
  const [showVideos, setShowVideos] = useState(true);
  const [showAffiches, setShowAffiches] = useState(true);

  const renderPortfolioItems = (data) => {
    return data.map((item, i) => {
      const content = item.getImageComponent ? (
        item.getImageComponent()
      ) : item.vid ? (
        <iframe
          src={item.vid}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded video"
          style={{ width: '100%', height: '100%', minHeight: '300px' }}
        />
      ) : (
        <img
          src={item.img}
          alt=""
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      );

      return (
        <div key={i} className="po_item">
          {content}
          <div className="content">
            <p>{item.description}</p>
            {item.link && (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                Voir
              </a>
            )}
          </div>
        </div>
      );
    });
  };

  // Function to toggle section and icon
  const SectionTitle = ({ title, onClick, isOpen }) => (
    <div className="section-title" onClick={onClick}>
      <h2>{title}</h2>
      {isOpen ? <FaAngleUp /> : <FaAngleDown />}
    </div>
  );

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="12">
            <h1 className="display-4 mb-4">Portfolio</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <SectionTitle
              title="Affiches"
              onClick={() => setShowAffiches(!showAffiches)}
              isOpen={showAffiches}
            />
            {showAffiches && (
              <div
                className={`collapsible-content ${showAffiches ? 'active' : ''}`}
              >
                <div className="mb-5 po_items_ho">
                  {renderPortfolioItems(dataportfolioAffiches)}
                </div>
              </div>
            )}
          </Col>
          <Col lg="12">
            <SectionTitle
              title="Illustrations"
              onClick={() => setShowImages(!showImages)}
              isOpen={showImages}
            />
            {showImages && (
              <div
                className={`collapsible-content ${showImages ? 'active' : ''}`}
              >
                <div className="mb-5 po_items_ho">
                  {renderPortfolioItems(dataportfolioImg)}
                </div>
              </div>
            )}
          </Col>
          <Col lg="12">
            <SectionTitle
              title="Videos"
              onClick={() => setShowVideos(!showVideos)}
              isOpen={showVideos}
            />
            {showVideos && (
              <div
                className={`collapsible-content ${showVideos ? 'active' : ''}`}
              >
                <div className="mb-5 po_items_ho">
                  {renderPortfolioItems(dataportfolioVideo)}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
