import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withRouter from '../hooks/withRouter';
import { Home } from '../pages/home';
import { Portfolio } from '../pages/portfolio';
import { ContactUs } from '../pages/contact';
import { About } from '../pages/about';
import { Socialicons } from '../components/socialicons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// for WIP page
import { WorkInProgress } from '../pages/wip';

const AnimatedRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Portfolio />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/wip" element={<WorkInProgress />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
