import { get } from 'firebase/database';
import FirebaseImage from './components/firebaseImage';

const logotext = 'JHUNLILI';
const meta = {
  title: 'JHUNLILI',
  description:
    'Lydia Abid : communication graphique, webdesign, illustration, graphisme et identité visuelle.',
};

const introdata = {
  title: 'JHUNLILI // LYDIA ABID',
  animated: {
    first: 'Animation/Motion design',
    second: "Création d'affiches",
    third: 'Illustration',
  },
  description:
    'Étudiante en arts et graphiste, je suis passionnée par le graphisme, l’illustration et le motion design.',
  your_img_url: '',
};

const dataabout = {
  title: 'Un peu sur moi',
  aboutme:
    "Je m'appelle Lydia Abid, j'ai 22 ans et je suis une étudiante en arts passionnée par le graphisme. Mon parcours artistique se concentre principalement sur l'animation, le motion design et l'illustration. J'éprouve une véritable passion pour Illustrator, un outil avec lequel je m'épanouis pleinement. Mon objectif est de créer des œuvres qui captivent et inspirent, tout en repoussant les limites de ma créativité. À travers mon portfolio, je vous invite à découvrir mon univers artistique et à partager ma passion pour le design graphique.",
};

const studytimeline = [
  {
    jobtitle: 'Etudiante',
    where: 'BAC +3 DEUG Arts plastiques - Université Paris 8',
    date: '2020-2024',
  },
  {
    jobtitle: 'Etudiante',
    where: 'BAC Litéraire - Lycée',
    date: '2020',
  },
];

const formationtimeline = [
  {
    jobtitle: '[PSSM]',
    where: 'Formation Premier Secours en Santé Mentale',
    date: '2024',
  },
  {
    jobtitle: '[Civique et Citoyenne]',
    where: 'Education aux médias et à l’information',
    date: '2023',
  },
  {
    jobtitle: '[PSC1]',
    where: 'Prévention, Secourisme, Premiers Secours',
    date: '2023',
  },
];

const worktimeline = [
  {
    jobtitle: 'Service Civique',
    where: 'Cyberbase - Service Civique',
    date: '2023-2024',
  },
  {
    jobtitle: 'Graphiste',
    where: 'Sentinel - Association Esport',
    date: '2023',
  },
  {
    jobtitle: 'Graphiste',
    where: 'Oracle - Association Esport',
    date: '2023',
  },
  {
    jobtitle: 'Graphiste',
    where: 'Stelios - Association Esport',
    date: '2022',
  },
];

const skills = [
  {
    name: 'Illustrator',
    value: 99,
  },
  {
    name: 'Photoshop',
    value: 75,
  },
  {
    name: 'After Effects',
    value: 45,
  },
  {
    name: 'Premiere Pro',
    value: 30,
  },
  {
    name: 'Animate',
    value: 50,
  },
  {
    name: 'Light Room',
    value: 60,
  },
  {
    name: 'Figma',
    value: 60,
  },
  {
    name: 'InDesign',
    value: 80,
  },
  {
    name: 'Blender',
    value: 20,
  },
];

const services = [
  {
    title: 'Animation/Motion design',
    description:
      "Je crée des animations en rotoscopie sur Animate à partir de vidéos que vous m'envoyez. Ou je réalise un motion design sur After Effect pour votre logo ou votre illustration.",
  },
  {
    title: 'Illustration',
    description:
      "Je réalise des illustrations selon vos préférences, en partant d'un croquis ou suivant une description détaillée, tout en restant à votre disposition pour vous guider.",
  },
  {
    title: "Création d'affiches",
    description:
      "Je conçois vos affiches en répondant fidèlement à vos spécifications, tout en intégrant mon expertise en impression afin d'assurer une qualité optimale.",
  },
];

const dataportfolioImg = [
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/telephone.svg" />,
    description: 'Téléphone conçu sur Illustrator à la Cyberbase en 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/cabanon.svg" />,
    description:
      'Cabanon, créé sur Illustrator à la Cyberbase destiné à une utilisation en motion design sur After Effects, 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/illustrator.svg" />,
    description:
      'Carte conceptuelle réalisée à la Cyberbase en 2024 avec Illustrator.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/main_haut.svg" />,
    description:
      'Illustration main index, realisé à la tablette graphique sur illustrator, 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/main.svg" />,
    description:
      'Illustration main index pouce, realisé à la tablette graphique sur illustrator, 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/voiture.svg" />,
    description:
      'Voiture millitaire, réalisée à la cyberbase sur Illustrator destinée à une utilisation en motion design sur After Effect, 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/alberte.svg" />,
    description:
      "Logo fictif réalisé sur base d'un guide de marque, réalisé a la Cyberbase 2024.",
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/alberte2.svg" />,
    description:
      "Carte de visite, réalisé sur Illustrator par base d'un guide de marque fictif à la Cyberbase 2024",
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/logo_jhunlili.svg" />
    ),
    description: 'Création du logo Liid, pour moi même, Illustrator, 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/voiture_mimi.svg" />
    ),
    description:
      'Voiture mignonne, réalisé à la Cyberbase sur Illustrator destiné à une utilisation en motion design sur After Effect, 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/gateau.svg" />,
    description:
      'Ustensile de cuisine dessiné sur Illustrator en 2023, destiné à être animé en motion design sur After Effects.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/basket.svg" />,
    description:
      'Éléments de basket et ballon 3D conçus sur Illustrator et destinés à une utilisation en motion design sur After Effects, créés à la Cyberbase en 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/etoile.svg" />,
    description: 'Icône de CV réalisé sur Illustrator en 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/lait.svg" />,
    description:
      'Brique de lait, réalisé sur Illustrator à la Cyberbase en 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/rpgmv_icons.svg" />,
    description:
      'Émoticône réalisé pour le jeu "Etheria" fait sur Illustrator, 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/artisan_eau.svg" />,
    description:
      "Logo fictif réalisé sur base d'un guide de marque, réalisé a la Cyberbase 2024.",
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/miaou.svg" />,
    description: 'Canette miaou souriant, Illustrator 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/mix.svg" />,
    description: 'Random, Illustrator à la Cyberbase, 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/main_parfum.svg" />,
    description: 'Main parfum, Illustrator, 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/oiseau.svg" />,
    description: 'Wazo créé sur Illustrator en 2024.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/coliseum.svg" />,
    description: 'Croquis romain réalisé sur Illustrator en 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/immeuble_rue.svg" />
    ),
    description: "Croquis d'un lieu parisien réalisé sur Illustrator en 2024.",
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/qgdonut_logo.svg" />
    ),
    description:
      'Variation du logo QG JEUNE, réalisé sur illustrator à la Cyberbase en 2024.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/oeil_vagabond.svg" />
    ),
    description:
      "L'Oeil vagabond, Logo réalisé sur Illustrator par base d'un guide de marque factif à la Cyberbase 2024.",
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="/Affiches/coeur.svg" />,
    description: 'Coeur à lunettes, Illustrator, 2024.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/telephone_violet.svg" />
    ),
    description:
      'Téléphone violet, conçu sur Illustrator à la Cyberbase en 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/voiture_croquis.svg" />
    ),
    description: "Croquis d'une voiture réalisé sur Illustrator en 2023.",
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/logo_delices.svg" />
    ),
    description:
      "Logo fictif réalisé sur base d'un guide de marque, réalisé a la Cyberbase 2024.",
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/sentinel_logo.svg" />
    ),
    description: 'Croquis et logos sentinel, réalisé sur Illustrator, 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Affiches/sentinel_logo2.svg" />
    ),
    description: 'Croquis et logos sentinel, réalisé sur Illustrator, 2023.',
    link: '',
  },
];

const dataportfolioVideo = [
  {
    vid: 'https://www.youtube.com/embed/g8ca0_3ylQI',
    description:
      'Clip vidéo Emily Jamz, réalisé pour un cours, fait sur OpenToonz et monté sur Sony Vegas pro en 2021.',
    link: 'https://www.youtube.com/watch?v=g8ca0_3ylQI',
  },
  {
    vid: 'https://www.youtube.com/embed/4Lacf4W7SaI',
    description:
      'Video fin du monde 2012, réalisé pour un cours complotiste, première pro - Sony Vegas pro, 2022.',
    link: 'https://www.youtube.com/watch?v=4Lacf4W7SaI',
  },
  {
    vid: 'https://www.youtube.com/embed/BqsdFRFGYps',
    description:
      "Folie I, c'est la réalisation vidéo 1/2 de mon mémoire, mixant rotoscopie et vidéo thème : expression de la pensée intime. Animate, Premiere pro, Sony vegas, 2023.",
    link: 'https://www.youtube.com/watch?v=BqsdFRFGYps',
  },
  {
    vid: 'https://www.youtube.com/embed/hI5bpQ7XhjM',
    description:
      "Folie II, c'est la réalisation vidéo 2/2 de mon mémoire, mixant rotoscopie et vidéo thème : expression de la pensée intime. Animate, Premiere pro, Sony vegas, 2023.",
    link: 'https://www.youtube.com/watch?v=hI5bpQ7XhjM',
  },
  {
    vid: 'https://www.youtube.com/embed/h8i3eD4YAsY',
    description: 'Télécommande, Animate, Mémoire, 2021.',
    link: 'https://www.youtube.com/watch?v=h8i3eD4YAsY',
  },
  {
    vid: 'https://www.youtube.com/embed/UAcg_8qg5UM',
    description: 'Main mur, rotoscopie réalisé sur Animate, en 2022.',
    link: 'https://www.youtube.com/watch?v=UAcg_8qg5UM',
  },
  {
    vid: 'https://www.youtube.com/embed/QTR7K25UxDw',
    description: 'Rotoscopie groupe sans visage, Animate, Mémoire, 2022.',
    link: 'https://www.youtube.com/watch?v=QTR7K25UxDw',
  },
  {
    vid: 'https://www.youtube.com/embed/kZELgd63OkY',
    description: 'Rotoscopie groupe, Animate, Mémoire, 2022.',
    link: 'https://www.youtube.com/watch?v=kZELgd63OkY',
  },
  {
    vid: 'https://www.youtube.com/embed/wMxiCe-y53o',
    description: 'Ballon 3D, Illustrator et After Effects, 2023.',
    link: 'https://www.youtube.com/watch?v=wMxiCe-y53o',
  },
  {
    vid: 'https://www.youtube.com/embed/erq7-jyJO9w',
    description:
      'Mon logo Jhunlili, motion design After effects et Illustrator, 2024.',
    link: 'https://www.youtube.com/watch?v=erq7-jyJO9w',
  },
  {
    vid: 'https://www.youtube.com/embed/-q-HVMdtlAU',
    description:
      "Panier fou !, réalisé a la cyberbase, utilisation d'Illustrator et After Effects, 2023.",
    link: 'https://www.youtube.com/watch?v=-q-HVMdtlAU',
  },
  {
    vid: 'https://www.youtube.com/embed/akly_0HRlNc',
    description: 'Ustensiles, After effects et Illustrator, 2023.',
    link: 'https://www.youtube.com/watch?v=akly_0HRlNc',
  },
  {
    vid: 'https://www.youtube.com/embed/M_iXPMNFwiM',
    description: 'Homme en colère, Animate, Mémoire, 2022.',
    link: 'https://www.youtube.com/watch?v=M_iXPMNFwiM',
  },
  {
    vid: 'https://www.youtube.com/embed/7rw0f7rqva8',
    description: 'Personne apaisée, Animate, Mémoire, 2022.',
    link: 'https://www.youtube.com/watch?v=7rw0f7rqva8',
  },
  {
    vid: 'https://www.youtube.com/embed/SPpd5fRJkAU',
    description: 'Samouraï, Animate, 2024.',
    link: 'https://youtube.com/shorts/SPpd5fRJkAU',
  },
];

const dataportfolioAffiches = [
  {
    getImageComponent: () => <FirebaseImage path="affiche_clef_blank.jpg" />,
    description: 'Photographie, égalisé par mes soins, Light Room,2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="affiche_clef.webp" />,
    description:
      'Affiche, thème : sensibilisation des dangers de la surexposition aux écrans fait sur Illustrator. Cyberbase,2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayer_Tiennort.png" />
    ),
    description: 'Sentinel - Association Esport - Tiennort, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayer_Kayzy.png" />
    ),
    description: 'Sentinel - Association Esport - Kayzy, Photoshop 2023',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayer_Juhako.png" />
    ),
    description: 'Sentinel - Association Esport - Juhako, Photoshop 2023',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayer_Falcgor.png" />
    ),
    description: 'Sentinel - Association Esport - Falcgor, Photoshop 2023',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayer_Bainjasmin.png" />
    ),
    description: 'Sentinel - Association Esport - Bainjasmin, Photoshop 2023',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel/SentinelPlayers.png" />
    ),
    description:
      'Affiche présentation 5 joueurs, Sentinel Hydra, Photoshop, 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_Vilow.png" />
    ),
    description: 'Sentinel - Association Esport - Vilow, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_Tora.png" />
    ),
    description: 'Sentinel - Association Esport - Tora, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_Edeern.png" />
    ),
    description: 'Sentinel - Association Esport - Edeern, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_Dydy.png" />
    ),
    description: 'Sentinel - Association Esport - Dydy, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_Creepano.png" />
    ),
    description: 'Sentinel - Association Esport - Creepano, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Sentinel_2/SentinelPlayer_2.png" />
    ),
    description:
      'Sentinel - Association Esport - Fight As One, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="MatchDay_Insta.png" />,
    description:
      'MatchDay - Association Esport - Post Instagram, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => <FirebaseImage path="MatchDay_Insta3.png" />,
    description:
      'MatchDay - Association Esport - Post Instagram, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Dofus/Match1-2_Dofus2023.png" />
    ),
    description: 'MatchDay - Association Esport - Dofus, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Dofus/HuitDeFinale_Dofus2023.png" />
    ),
    description:
      'Huitième de finale - Association Esport - Dofus, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Dofus/QuartDeFinale_Dofus2023.png" />
    ),
    description:
      'Quart de dinale - Association Esport - Dofus, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Dofus/DemiFinale_Dofus2023.png" />
    ),
    description: 'Demi-finale - Association Esport - Dofus, Photoshop 2023.',
    link: '',
  },
  {
    getImageComponent: () => (
      <FirebaseImage path="/Dofus/Finale_Dofus2023.png" />
    ),
    description: 'Finale - Association Esport - Dofus, Photoshop 2023.',
    link: '',
  },
];

const contactConfig = {
  YOUR_EMAIL: 'lydia.abid@hotmail.com',
  // YOUR_PHONE: '', NE PAS UTILISER
  description:
    'N’hésitez pas à me contacter pour toute de renseignements ou pour toute autre question. Je vous répondrai dans les plus brefs délais.',
  // possibilité de serveur emailjs pour envoyer des emails
  // tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: 'service_id',
  YOUR_TEMPLATE_ID: 'template_id',
  YOUR_USER_ID: 'user_id',
};
// socialicons affiche les icones de réseaux sociaux lorsque appelé
// chemin: src/components/socialicons/index.js
const socialprofils = {
  github: 'https://github.com/Marksu-u',
  behance: 'https://behance.net/lydiajhun',
  // linkedin: 'https://linkedin.com',
  twitter: 'https://twitter.com/Jhunlili',
};
export {
  meta,
  dataabout,
  dataportfolioImg,
  dataportfolioVideo,
  dataportfolioAffiches,
  studytimeline,
  formationtimeline,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
