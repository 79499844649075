import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import { Link } from 'react-router-dom';

export const WorkInProgress = () => {
  return (
    <HelmetProvider>
      <section className="wip-section">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Travail en cours</title>
          <meta
            name="description"
            content="Cette page est actuellement en construction. Revenez bientôt pour voir les mises à jour."
          />
        </Helmet>
        <div className="intro_sec d-flex align-items-center justify-content-center">
          <div className="text text-center">
            <h2 className="mb-1x">Oups ! Ce n'est pas encore disponible</h2>
            <p className="mb-1x">
              Nous travaillons dur pour vous apporter de nouveaux contenus à
              explorer ! Restez à l'écoute !
            </p>
            <Link to="/">
              <div className="ac_btn btn">
                Retour à l'accueil
                <div className="ring one"></div>
                <div className="ring two"></div>
                <div className="ring three"></div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
